.headerRow {
  min-height: 50px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.imageStride {
  height: 40px;
  width: 40px;
}

.st-link {
  text-align: center;
  justify-content: center;
  align-items: center;
}
