.st-text {
  font-family: "Mabry Mono";
  text-align: center;

  font-style: normal;
  font-weight: lighter;

  /* Neutral/Black */

  color: #262223;
  margin: auto;
}

.st-text span {
  color: #900a34;
  font-weight: bolder;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
