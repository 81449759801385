.menuItem {
  height: 80vh;
  width: 70vw;
  margin: 40px;
  align-content: center;
  /*background-color: #eeeeee;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuItem p {
  margin: auto;
  position: absolute;
}

.imageMenuItem {
  height: 80vh;
  width: 80vw;
  object-fit: contain;
  align-content: center;
}

.leftBlink {
  width: 10vw;
  background-color: #a31b43;
  height: 5px;
  position: absolute;

  left: 0;
}

.rightBlink {
  width: 10vw;
  background-color: #a31b43;
  height: 5px;
}
